export const setDescriptionStyles = () => ({
  h6: {
    marginBottom: '0px',
    color: 'white',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: 'normal',
  },
  p: {
    color: 'white',
    lineHeight: 'normal',
    marginBottom: '24px',
    fontSize: '16px',
  },
  br: {
    display: 'block',
    marginTop: '12px',
    content: '""',
  },
});
