import React from 'react';

import { RichText } from '@sprinklr/shared-lib';
import { Box, Flex, Text, ThemeUIStyleObject, IconButton } from 'theme-ui';

import { setDescriptionStyles } from './styles';

import CloseIcon from '@sprinklr/shared-lib/assets/svgs/close.svg';

import { EventCardItem } from '../types';

export const DetailedView = ({
  description,
  className,
  onClose,
}: Pick<EventCardItem, 'description'> & {
  className: ThemeUIStyleObject;
  onClose: () => void;
}) => {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'end',
        ...className,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          opacity: 0.6,
          position: 'absolute',
        }}
      />
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          color: 'white',
          paddingX: ['20px', null, null, '30px', '40px'],
          paddingTop: ['20px', '30px', '40px', '50px', '60px'],
          paddingBottom: '40px',
          flexDirection: 'column',
          zIndex: 1,
        }}
      >
        <Box sx={setDescriptionStyles()}>
          <RichText richTextObject={description} />
        </Box>
      </Flex>
      <IconButton
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          fill: 'white',
          height: '20px',
          width: '20px',
          cursor: 'pointer',
          ':hover': { backgroundColor: 'spaceGrey' },
          zIndex: 2,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Flex>
  );
};
