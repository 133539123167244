import React, { useCallback, useState } from 'react';

import { BackgroundImage, ImageBlock } from '@sprinklr/shared-lib';
import { Box, Flex } from 'theme-ui';
import { DetailedView } from './components/DetailedView';
import { DefaultView } from './components/DefaultView';

import { EventCardItem } from './types';

import { VIEW_TYPES } from './constants';

const EventCard = ({
  item: { city, date, backgroundImage, description },
}: {
  item: EventCardItem;
}) => {
  const [viewType, setViewType] = useState<string>(VIEW_TYPES.DEFAULT);

  const closeDetailView = useCallback(
    () => setViewType(VIEW_TYPES.DEFAULT),
    [],
  );
  const openDetailView = useCallback(
    () => setViewType(VIEW_TYPES.DETAILED),
    [],
  );

  const isDefaultView = viewType === VIEW_TYPES.DEFAULT;

  return (
    <Flex
      sx={{
        height: ['508px', '420px'],
        width: '100%',
        position: 'relative',
        zIndex: 0,
      }}
    >
      <BackgroundImage
        image={backgroundImage?.asset}
        mobileImage={backgroundImage?.mobileAsset}
      />
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
      >
        <DefaultView
          city={city}
          date={date}
          onCTACLick={openDetailView}
          className={{
            opacity: isDefaultView ? 1 : 0,
            pointerEvents: isDefaultView ? 'auto' : 'none',
            transition: '0.3s',
            position: 'absolute',
          }}
        />
        <DetailedView
          description={description}
          onClose={closeDetailView}
          className={{
            opacity: isDefaultView ? 0 : 1,
            pointerEvents: isDefaultView ? 'none' : 'auto',
            transition: '0.3s',
            position: 'absolute',
          }}
        />
      </Box>
    </Flex>
  );
};

export default EventCard;
