import React from 'react';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';

import { Flex, Text, ThemeUIStyleObject, Box } from 'theme-ui';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { EventCardItem } from '../types';

export const DefaultView = ({
  city,
  date,
  className,
  onCTACLick,
}: Pick<EventCardItem, 'date' | 'city'> & {
  className: ThemeUIStyleObject;
  onCTACLick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: '24px',
        ...className,
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'flex-end',
          flexGrow: 1,
        }}
      >
        <Text
          sx={{
            fontWeight: '700',
            fontSize: ['22px', '37.5px'],
            color: '#fff',
            lineHeight: 'normal',
          }}
        >
          {city}
        </Text>
      </Flex>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <Text
          sx={{
            fontWeight: '500',
            fontSize: ['18px', '28px'],
            color: '#fff',
            lineHeight: '28px',
          }}
        >
          {formatInTimeZone(new Date(date), 'UTC', 'MMM dd, yyyy')}
        </Text>
        <Text
          sx={{
            marginLeft: '12px',
            fontWeight: '700',
            fontSize: '15px',
            lineHeight: '30px',
            color: '#fff',
            textDecoration: 'underline',
            cursor: 'pointer',
            flexGrow: 0,
            textTransform: 'uppercase',
            letterSpacing: '0.9px',
          }}
          onClick={onCTACLick}
          aria-label={t('Learn more')}
        >
          {t('Learn more')}
        </Text>
      </Flex>
    </Flex>
  );
};
